.project-page section {
  margin: 0;
  padding: 15px 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-page section {
    padding: 0px 0;
  }
}

/*//////////////////////////////
ATF
/////////////////////////////*/

section.project-atf {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--black);
  padding: 80px 0;
  margin-bottom: 20px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  section.project-atf {
    padding: 50px 0;
  }
}

.project-atf .project-title {
  width: 100%;
}

.project-atf .project-title h1 {
  text-align: left;
  margin: 0;
}

.project-atf .project-title p {
  text-transform: uppercase;
  font-size: 16px;
}

.project-atf .left {
  width: 65%;
}


.project-atf .left p {
  font-size: 20px;
}

.project-atf .left a {
  text-decoration: none;
  color: var(--black);
  position: relative;
  display: inline-block;
  margin-top: 15px;
  text-transform: uppercase;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 600;
}

.project-atf .left a::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.project-atf .left a:hover:after {
  opacity: 1;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .project-atf .left {
    width: 80%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-atf .left {
    width: 100%;
  }
}

.project-atf .right {
  width: 25%;
}

.project-atf .right h2 {
  font-family: var(--bodyFont);
  font-size: 24px;
  margin: 22px 0 0 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.project-atf .right ul {
  padding: 0;
}

.project-atf .right ul li {
  list-style: none;
  margin: 10px 0;
  font-size: 20px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .project-atf .right {
    display: none;
  }
}

/*//////////////////////////////
Three Images Acvross
/////////////////////////////*/

.project-page .three-across {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-page .three-across img {
  width: 32%;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-page .three-across img {
    width: 100%;
    padding: 10px 0;
  }
}

/*//////////////////////////////
Two Images Across
/////////////////////////////*/

.project-page .two-across {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-page .two-across img:first-child {
  width: 32%;
}

.project-page .two-across img:nth-child(2) {
  width: 66%;
}

.project-page .two-across-alt img:first-child {
  width: 66%;
}

.project-page .two-across-alt img:nth-child(2) {
  width: 32%;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-page .two-across img:first-child {
    width: 100%;
    padding: 10px 0;
  }

  .project-page .two-across img:nth-child(2) {
    width: 100%;
    padding: 10px 0;

  }
}

/*//////////////////////////////
One Image Across
/////////////////////////////*/

.project-page .one-across {
  display: flex;
  justify-content: space-between;
}

.project-page .one-across img {
  width: 100%;
}


@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-page .one-across img {
    padding: 10px 0;
  }
}


/*//////////////////////////////
Project Footer
/////////////////////////////*/

section.project-footer {
  width: 80%;
  max-width: 1500px;
  margin: 120px auto;
}

section.project-footer h2 {
  font-family: var(--bodyFont);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0 0 30px 0;
}

section.project-footer .project-links h3 {
  display: inline-block;
  margin: 0;
  margin: 0 20px 0 0;
  font-size: 52px;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  section.project-footer {
    width: 90%;
  }

  section.project-footer .project-links h3 {
    font-size: 30px;
    margin: 0 10px 0 0;
  }
}

section.project-footer .project-links h3 a {
  position: relative;
}

section.project-footer .project-links h3 a:after {
  content: "";
  width: 98%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 55%;
  left: -2.5%;
  opacity: 0;
}

section.project-footer .project-links h3 a:hover:after {
  opacity: 1;
}

section.project-footer .project-links h3 a {
  color: var(--black);
  text-decoration: none;
  margin: 0;
}