.Header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  height: 110px;
  z-index: 2;
  border-bottom: 1px solid var(--black);
}

.h-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  height: 105px;
}

.header-banner {
  width: 100%;
  background-color: var(--neutral);
}

.header-banner a {
  display: block;
  width: 100%;
  text-decoration: none;
  color: var(--black);
  padding: 15px 0;
  text-align: center;
  font-size: 20px;
}

.logo,
.contact {
  z-index: 99;
}

.logo a {
  text-decoration: none;
  color: var(--black);
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .Header {
    height: 90px;
  }

  .h-wrapper {
    height: 90px;
  }

  .logo a {
    font-size: 18px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .Header {
    height: 80px;
  }

  .logo a {
    font-size: 14px;
    margin-left: 35px;
  }

  .header-banner a {
    font-size: 14px;
  }

  .h-wrapper {
    height: 73px;
  }
}

.contact a {
  text-decoration: none;
  color: var(--black);
  text-transform: lowercase;
  font-weight: 900;
  font-size: 18px;
  width: 3rem;
}


/* ////////////////////////////////////////////
 Hamburger Menu 
 /////////////////////////////////////////////*/

.hamburger {
  width: 2.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  transition: all 1s ease-in-out;
  margin-right: 10px;
}

.burger {
  width: 2.3rem;
  height: 1px;
  border-radius: 0px;
  background-color: var(--black);
  transform-origin: 1px;
  transition: all 0.2s ease-in-out;
}

.burger:nth-child(2) {
  height: 1.12px;
}

.navigation {

  transition: all 3s ease-in-out;
}

.navigation ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: var(--white);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 10;

}

.navigation ul li {
  list-style-type: none;
  padding-right: 10px;
}

.navigation ul li a::before {
  content: "01.";
  color: var(--black);
  position: absolute;
  bottom: 0;
  left: -20px;
}

.navigation ul li a {
  position: relative;
  color: var(--black);
  font-family: var(--headerFont);
  text-decoration: none;
  font-size: 72px;
  font-weight: 300;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation ul li a::before {
  content: "01.";
  color: var(--black);
  position: absolute;
  top: 45px;
  left: -25px;
  font-size: 20px;
}

.navigation ul li:nth-child(2) a::before {
  content: "02.";
}

.navigation ul li:nth-child(3) a::before {
  content: "03.";
}

.navigation ul li:nth-child(4) a::before {
  content: "04.";
}

.navigation ul li:nth-child(5) a::before {
  content: "05.";
}

.navigation ul li:first-child a {
  top: 35%;
}

.navigation ul li:nth-child(2) a {
  top: 42%;
}

.navigation ul li:nth-child(3) a {
  top: 50%;
}

.navigation ul li:nth-child(4) a {
  top: 58%;
}

.navigation ul li:last-child a {
  top: 66%;
}

.navigation ul li a::after {
  content: "";
  width: 120%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 55%;
  /* transform: translateY(-50%); */
  left: -10%;
  opacity: 0;
}


.navigation ul li a:hover:after {
  opacity: 1;
}