.section {
  margin: 250px 0;
}

.section-1 {
  margin-top: 100px
}

@media only screen and (pointer: coarse) and (max-width: 1024px),
screen and (max-width: 1024px) {
  .section {
    margin: 150px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .section {
    margin: 0px 0;
  }

  .project {
    margin: 80px 0;
  }

  .section-1 {
    margin-top: 50px
  }
}

.project h2,
.project p,
.project a {
  color: var(--black);
  margin: 0;
  text-decoration: none !important;
}

.project h2 {
  line-height: 1;
  margin-bottom: 10px;
  font-style: italic;
}

.project p {
  margin-top: 5px;
}

.project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/*//////////////////////////////
Lanscape Projects 
/////////////////////////////*/

.section-1,
.section-3,
.section-5,
.section-7,
.section-9 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.project-landscape {
  width: 50%;
  max-width: 1000px;
  position: relative;
}

.section-3 .project-landscape,
.section-7 .project-landscape {
  width: 35%;
}

@media only screen and (pointer: coarse) and (max-width: 1024px),
screen and (max-width: 1024px) {
  .project-landscape {
    width: 55%;
  }

  .section-3 .project-landscape,
  .section-7 .project-landscape {
    width: 45%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-landscape {
    width: 100%;
  }

  .section-3 .project-landscape,
  .section-7 .project-landscape {
    width: 100%;
  }
}


/*//////////////////////////////
Two Across Projects 
/////////////////////////////*/

.section-2,
.section-6,
.section-8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.project-two-across {
  justify-content: space-between;
}

.section-2 .project-1,
.section-6 .project-1 {
  width: 45%;
}

.section-2 .project-2,
.section-6 .project-2 {
  width: 35%;
}

.section-8 .project-1 {
  width: 30%;
}

.section-8 .project-2 {
  width: 40%;
}

@media only screen and (pointer: coarse) and (max-width: 1024px),
screen and (max-width: 1024px) {

  .section-2 .project-1,
  .section-6 .project-1 {
    width: 50%;
  }

  .section-2 .project-2,
  .section-6 .project-2 {
    width: 40%;
  }

  .section-8 .project-1 {
    width: 35%;
  }

  .section-8 .project-2 {
    width: 45%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {

  .section-2 .project-1,
  .section-6 .project-1 {
    width: 100%;
  }

  .section-2 .project-2,
  .section-6 .project-2 {
    width: 100%;
  }

  .section-8 .project-1 {
    width: 100%;
  }

  .section-8 .project-2 {
    width: 100%;
  }
}



/*//////////////////////////////
Four Across Project
/////////////////////////////*/

.section-4 {
  display: block;
}

.project-four-across .pr-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.project-four-across .pr-image img {
  width: 24%;
  max-width: 500px;
}

.project-four-across .pr-image img a {
  position: relative;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .project-four-across .pr-image img {
    width: 100%;
  }

  .project-four-across .pr-image img:nth-child(1),
  .project-four-across .pr-image img:nth-child(3),
  .project-four-across .pr-image img:nth-child(4) {
    display: none;
  }
}