/*=====================================
=======================================
          Contact ATF
=======================================
======================================= */
.contact-atf {
  padding-bottom: 60px;
}

.contact-atf p {
  width: 90%;
  max-width: 600px;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .contact-atf h1 {
    font-size: 50px;
  }

  .contact-atf {
    padding: 100px 0 70px 0;
  }
}

.contact-atf::after {
  display: none;
}

/*=====================================
=======================================
          Contact Form
=======================================
======================================= */
.contact-form {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 0;
}

.contact-form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-form .each-input {
  width: 47%;
  margin: 10px 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .contact-form .each-input {
    width: 100%;
    margin: 10px 0;
  }
}

.contact-form .each-input label,
.contact-form .each-textarea label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

.contact-form .each-input input,
.contact-form .each-textarea textarea {
  width: 100%;
  padding: 5px;
  min-height: 24px;
  background-color: var(--white);
  border: 1px solid var(--black);
  font-family: var(--bodyFont);
  font-size: 20px;
}

.contact-form .each-input input::placeholder,
.contact-form .each-textarea textarea::placeholder {
  font-family: var(--bodyFont) !important;
  font-size: 20px;
}

.contact-form .each-textarea {
  width: 100%;
}

.contact-form .each-textarea textarea {
  min-height: 100px;
}

.contact-form .submit {
  background-color: var(--black);
  color: var(--white);
  border: none;
  padding: 10px 40px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: var(--bodyFont);
}


/*=====================================
=======================================
          Contact Footer
=======================================
======================================= */

.contact-footer {
  padding: 120px 0;
  text-align: center;
}

.contact-footer h3 {
  font-size: 44px;
  margin: 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .contact-footer {
    padding: 120px 0;
    text-align: center;
  }

  .contact-footer h3 {
    font-size: 40px;
    margin: 0;
  }

  .contact-footer p {
    width: 70%;
    margin: 20px auto;
  }
}