.atf p {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.service-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 70px;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .service-wrap {
    width: 95%
  }
}

.service-wrap .service {
  width: 45%;
  /* text-align: center; */
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .service-page .atf {
    padding-top: 70px;
  }

  .service-wrap .service {
    width: 100%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .service-wrap .service {
    width: 90%;
    margin: 0 auto;
  }
}

.service-wrap .service h2 {
  margin-bottom: 5px;
  font-family: var(--bodyFont) !important;
  margin: 40px 0 0 0;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
}

.service-wrap .service p {
  margin-top: 15px;
}