.intro,
.whats-included,
.how-it-works,
.intro,
.whos-this-for,
.shop-faq {
  padding: 80px 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .shop-page .atf {
    padding-top: 130px;
  }

  .intro,
  .whats-included,
  .how-it-works,
  .intro,
  .whos-this-for,
  .shop-faq {
    padding: 60px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {

  .shop-page .atf {
    padding-top: 100px;
  }

  .shop-page .atf h1 {
    font-size: 46px !important;
  }

  .intro,
  .whats-included,
  .how-it-works,
  .intro,
  .whos-this-for,
  .shop-faq {
    padding: 40px 0;
  }

  .intro h2 {
    font-size: 28px;
  }

  .intro br {
    display: none;
  }
}


.whats-included,
.how-it-works,
.whos-this-for,
.shop-faq {
  position: relative;
  margin-bottom: 0;
  margin: initial;
}

.whats-included::after,
.how-it-works::after,
.whos-this-for::after,
.intro .brand-kit-wrapper::after {
  content: "";
  width: 80%;
  height: .5px;
  background: var(--black);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.whats-included::after {
  height: .2px;
}

.intro h2,
.intro p {
  width: 90%;
  max-width: 1000px;
  margin: 20px auto;
  text-align: center;
}

/*=======================
=========================
       Shop Items 
=========================
========================= */

.intro .brand-kit-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 80px;
  padding-bottom: 80px;
  position: relative;
}


.intro .brand-kit-wrapper .each-shop-item {
  width: 30%;
  margin: 1.5%;
  text-decoration: none;
}

.intro .brand-kit-wrapper .each-shop-item h3 {
  font-size: 33px;
  text-align: center;
  margin: 10px 0;
  font-style: italic;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .intro .brand-kit-wrapper .each-shop-item {
    width: 47%;
  }

  .intro .brand-kit-wrapper .each-shop-item h3 {
    font-size: 30px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .intro .brand-kit-wrapper .each-shop-item {
    width: 95%;
    margin: 1.5% auto;
  }

  .intro .brand-kit-wrapper .each-shop-item h3 {
    font-size: 26px;
  }
}



/*=====================================
=======================================
          Shop Footer 
=======================================
======================================= */

/*=======================
=========================
    How it Works
=========================
========================= */

.how-it-works {
  text-align: center;
  padding-top: 10px !important;
}

.how-it-works h2 {
  margin: 0;
  font-size: 68px;
}

.how-it-works .title-text {
  width: 85%;
  max-width: 500px;
  margin: 10px auto;
}

.how-it-works .steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.how-it-works .steps .step {
  width: 31%;
  max-width: 270px;
  margin: 20px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .how-it-works .steps .step {
    width: 45%;
    margin: 4% 2%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .how-it-works .steps .step {
    width: 90%;
    max-width: 300px;
  }
}

.how-it-works .steps .step h3 {
  font-family: var(--bodyFont);
  font-size: 34px;
  margin-bottom: 0;
  margin-top: 0;
}

.how-it-works .steps .step h3 span {
  text-transform: uppercase;
}

/*===========================
=============================
      What's Included 
=============================
============================= */

.whats-included {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.whats-included .section-text {
  width: 52%;
  max-width: 500px;
  margin: 0 20px;
}

.whats-included .image {
  width: 30%;
  max-width: 500px;
  margin: 0 20px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  .whats-included .section-text {
    width: 40%;
  }

  .whats-included .image {
    width: 45%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .whats-included {
    flex-direction: column-reverse;
  }

  .whos-this-for {
    flex-direction: column;
  }

  .whats-included .section-text,
  .whats-included .image {
    width: 90%;
    max-width: 600px;
    margin: 0 20px;
  }
}

.whats-included h2 {
  margin-bottom: 5px;
}

.whats-included ul {
  padding: 0;
}

.whats-included ul li {
  list-style: none;
  padding: 0;
  margin: 5px 0;
  text-transform: uppercase;
  font-size: 17px;
}

/*=======================
=========================
    Shop FAQ
=========================
========================= */
.shop-faq {
  box-sizing: border-box;
}

.shop-faq h2 {
  text-align: center;
}

.shop-faq .faq-footer {
  text-align: center;
  padding-top: 60px;
  width: 80%;
  margin: 0 auto;
}

.shop-faq .faq-footer a {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  margin: 30px auto 0 auto;
  margin-top: 20px;
  display: inline-block;
}

.shop-faq .faq-footer a::after {
  content: "";
  width: 102%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 45%;
  left: -1%;
  opacity: 0;
}

.shop-faq .faq-footer a:hover:after {
  opacity: 1;
}

.shop-faq .container {
  max-width: 1200px;
  width: 85%;
  margin: 0 auto;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-faq .container {
    width: 90%;
  }
}

.shop-faq .faq-wrapper {
  border-bottom: 1px solid var(--black);
  overflow: hidden;
}

.shop-faq .faq-wrapper:first-child {
  border-top: 1px solid var(--black);
}

.shop-faq .faq-wrapper .question-container {
  width: 100%;
  text-align: left;
  padding: 10px;
  padding-bottom: 0;
  margin: -10px 0 -20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  letter-spacing: .3px;
  background: transparent;
  color: var(--black) !important;
  border: none;
  cursor: pointer;
  font-family: var(--bodyFont);
}

.question-container.active {
  background-color: var(--neutral) !important;
}

.shop-faq .faq-wrapper .question-container:hover {
  background-color: var(--neutral);
}

.shop-faq .faq-wrapper .arrow {
  font-size: 2rem;
  transition: .5s ease-in-out;
}

.shop-faq .arrow.active {
  rotate: 180deg;
}

button,
button p,
button svg {
  color: black !important;
}

.shop-faq .faq-wrapper .answer-container {
  padding: 0;
  transition: height .7s ease-in-out;
  width: 90%;
  max-width: 900px;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-faq .faq-wrapper .answer-container {
    width: 100%;
  }
}

.shop-faq .faq-wrapper .answer-content {
  padding: 30px 10px;
  margin-bottom: 0;
}